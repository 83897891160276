.example-container div {
    background: linear-gradient(-45deg, #145A32, #145A32, #229954, #145A32);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
    border-radius: 30px;
    width: 300px;
    height: 160px;
    margin-right: auto;
    margin-left: -15.25px;
}

.refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 5px;
    height: 5px;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}